import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CRMService {

  constructor(private httpClient: HttpClient) { }

  // CLIENTS
  public get(filters: any, currentMark: number | null, page?: number, sort?: MatSort) {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    const params: any = { page, ...filledFilters }
    if (currentMark) {
      params.mark = currentMark;
    }
    if (params.integration_date) {
      params.integration_date = moment(params.integration_date).format('Y-MM-DD')
      // params.integration_date = moment(filledFilters.integration_date).format('Y-MM-DD')
    }
    if (sort != undefined) {
      if (sort.active && sort.direction) {
        params.order = sort.active;
        params.type_order = sort.direction;
      }
    }
    return this.httpClient.get<any[]>(`${ environment.api }/crm_client`, {
      params
    })
  }

  public getClient(clientId: number) {
    return this.httpClient.get<any>(`${ environment.api }/crm_client/${ clientId }`)
  }

  public getClientType() {
    return this.httpClient.get<any>(`${ environment.api }/crm_client_type`)
  }

  public getRZs() {
    return this.httpClient.get<any>(`${ environment.api }/area_manager`)
  }

  public save(client_data: any, invoice_data: any, dealerInfo: any, id?: any) {
    if (id) {
      return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
        social_reason: client_data.social_reason,
        cif: client_data.cif,
        assign_to: client_data.assign_to,
        area_manager_id: client_data.rz,
        date_from: client_data.date_from,
        mail: client_data.mail,
        phone: client_data.phone,
        timetable: client_data.timetable,
        visit_timetable: client_data.visit_timetable,
        potencial_client: client_data.potencial_client,
        client_type_id: client_data.client_type,
        rac_type_id: client_data.rac_type,
        external_marks: client_data.exclusive,
        external_client: client_data.external_client,
        sale_objetive: client_data.sale_obj,
        types: client_data.vehicle_type,
        marks: client_data.marks,
        invoice_data: invoice_data,
        dealer_info: dealerInfo
      });
    } else {
      return this.httpClient.post<any>(`${ environment.api }/crm_client`, {
        social_reason: client_data.social_reason,
        assign_to: client_data.assign_to,
        cif: client_data.cif,
        area_manager_id: client_data.rz,
        date_from: client_data.date_from,
        mail: client_data.mail,
        phone: client_data.phone,
        timetable: client_data.timetable,
        visit_timetable: client_data.visit_timetable,
        potencial_client: client_data.potencial_client,
        client_type_id: client_data.client_type,
        rac_type_id: client_data.rac_type,
        external_marks: client_data.exclusive,
        external_client: client_data.external_client,
        sale_objetive: client_data.sale_obj,
        types: client_data.vehicle_type,
        marks: client_data.marks,
        invoice_data: invoice_data,
        dealer_info: dealerInfo
      });
    }
  }

  public saveWithDealer(dealerInfo: any, client_data: any, id?: any) {
    if (id) {
      return this.httpClient.post<any>(`${ environment.api }/crm_client_dealer/${ id }`, {
        dealer_info: dealerInfo,
        area_manager_id: client_data.rz,
        assign_to: client_data.assign_to,
        date_from: client_data.date_from,
        phone: client_data.phone,
        timetable: client_data.timetable,
        visit_timetable: client_data.visit_timetable,
        potencial_client: client_data.potencial_client,
        client_type_id: client_data.client_type,
        external_marks: client_data.exclusive,
        external_client: client_data.external_client,
        sale_objetive: client_data.sale_obj,
        types: client_data.vehicle_type,
      });
    } else {
      return this.httpClient.post<any>(`${ environment.api }/crm_client_dealer`, {
        dealer_info: dealerInfo,
        assign_to: client_data.assign_to,
        area_manager_id: client_data.rz,
        date_from: client_data.date_from,
        phone: client_data.phone,
        timetable: client_data.timetable,
        visit_timetable: client_data.visit_timetable,
        potencial_client: client_data.potencial_client,
        client_type_id: client_data.client_type,
        external_marks: client_data.exclusive,
        external_client: client_data.external_client,
        sale_objetive: client_data.sale_obj,
        types: client_data.vehicle_type,
      });
    }
  }

  public saveABCMatrix(abcMatrix: any, id: any) {

    return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
      abc_matrix: abcMatrix
    });

  }

  public saveWorkers(workers: any, id: any, autosave = false) {
    if (autosave) {
      return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
        contacts: [workers]
      });
    } else {
      return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
        contacts: workers
      });
    }
  }

  public saveCenters(centers: any, id: any, autosave = false) {
    if (autosave) {
      return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
        directions: [centers]
      });
    } else {
      return this.httpClient.post<any>(`${ environment.api }/crm_client/${ id }`, {
        directions: centers
      });
    }
  }

  public delete(clientId: any) {
    return this.httpClient.delete<any>(`${ environment.api }/crm_client/${ clientId }`, {
    })
  }

  public deleteElement(clientId: any, action: any, data: any) {
    return this.httpClient.put<any>(`${ environment.api }/crm_client_center_worker/${ clientId }`, {
      element: action,
      data: data
    })
  }

  public getCenter(centerId: any) {
    return this.httpClient.get<any>(`${ environment.api }/crm_client_center/${ centerId }`)
  }

  public getJobs() {
    return this.httpClient.get<any>(`${ environment.api }/worker_jobs`)
  }

  public export(filters: any, currentMark: number | null,): Observable<any> {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    const params: any = { ...filledFilters };
    if (currentMark) {
      params.mark = currentMark;
    }

    return this.httpClient.get(`${ environment.api }/clients_export`, {
      params,
      responseType: 'arraybuffer'
    });
  }

  public changeRz(clients: any, new_rz: any) {
    return this.httpClient.post(`${ environment.api }/change_rz`, {
      clients: clients,
      new_rz: new_rz
    });
  }

  public verifyCif(cif: any) {
    return this.httpClient.get<any>(`${ environment.api }/search_cif`, {
      params: { cif: cif }
    });
  }

  public setImg(img: File, clientId: any): Observable<any> {
    let formData = new FormData();
    formData.append('img', img, img.name);

    return this.httpClient.post<any>(`${ environment.api }/set_img/${ clientId }`,
      formData
    );
  }

  public getRacTypes() {
    return this.httpClient.get<any>(`${ environment.api }/rac_types`);
  }

  public getInfoDealer(info: any) {
    return this.httpClient.get<any>(`${ environment.api }/info_dealer`, {
      params: { data: info.id }
    });
  }

  // LEADS
  public getStates(countryId: number = 1) {
    return this.httpClient.get<any>(`${ environment.api }/states`, {
      params: { country_id: countryId }
    });
  }

  public getCities() {
    return this.httpClient.get<any>(`${ environment.api }/cities`);
  }

  public getLeads(filters: any, currentMark: number | null, page?: number, sort?: MatSort) {
    if (sort?.active && sort?.direction) {
        filters.order = sort.active;
        filters.type_order = sort.direction;
    }
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    const params: any = { page, ...filledFilters };
    if (currentMark) {
      params.mark = currentMark;
    }
    if (params.date_from) {
      params.date_from = moment(params.date_from).format('Y-MM-DD')
    }

    if (params.date_to) {
      params.date_to = moment(params.date_to).format('Y-MM-DD')
    }

    return this.httpClient.get<any>(`${ environment.api }/leads`, {
      params
    });
  }

  public saveLeads(data: any) {
    return this.httpClient.post<any>(`${ environment.api }/leads`, {
      ...data
    });
  }

  public editLead(data: any, leadId: number) {
    return this.httpClient.put<any>(`${ environment.api }/lead/${ leadId }`, {
      ...data
    });
  }

  public deleteLead(leadId: number) {
    return this.httpClient.delete<any>(`${ environment.api }/lead/${ leadId }`);
  }

  public getLead(leadId: number) {
    return this.httpClient.get<any>(`${ environment.api }/lead/${ leadId }`);
  }

  public getLeadOrigins() {
    return this.httpClient.get<any>(`${ environment.api }/lead_origins`);
  }

  public closeLead(leadId: number, close: boolean) {
    return this.httpClient.put<any>(`${ environment.api }/close_lead/${ leadId }`, {
      closed: close
    });
  }

  public getContactMethods() {
    return this.httpClient.get<any>(`${ environment.api }/contact_methods`);
  }

  public addSaleRetry(retryData: any, comment: any, discounts: any, lead: number) {
    return this.httpClient.post<any>(`${ environment.api }/retries/${ lead }`, {
      name: retryData.name,
      date: retryData.date,
      contact_method: retryData.contact_type,
      catalog_vehicle: retryData.vehicle,
      comment: comment,
      color: retryData.color,
      discounts: discounts
    });
  }

  public showOfert(ofertId: number) {
    return this.httpClient.get<any>(`${ environment.api }/oferts/${ ofertId }`);
  }

  public getReminderTypes() {
    return this.httpClient.get<any>(`${ environment.api }/reminder_types`);
  }

  public addReminder(ofertId: number, reminders: any) {
    return this.httpClient.post<any>(`${ environment.api }/reminder/${ ofertId }`, {
      reminders
    });
  }

  public deleteOfert(ofertId: number) {
    return this.httpClient.delete<any>(`${ environment.api }/oferts/${ ofertId }`);
  }

  public updateOfert(ofertId: number, retryData: any, comment: any, discounts: any, state: any) {
    return this.httpClient.put<any>(`${ environment.api }/oferts/${ ofertId }`, {
      name: retryData.name,
      date: retryData.date,
      contact_method: retryData.contact_type,
      catalog_vehicle: retryData.vehicle,
      comment: comment,
      color: retryData.color,
      discounts: discounts,
      state: state
    });
  }

  public getOfertStates() {
    return this.httpClient.get<any>(`${ environment.api }/ofert_states`);
  }

  public exportLeads() {
    return this.httpClient.get(`${ environment.api }/export_leads`, {
      responseType: 'arraybuffer'
    });
  }

  public importLeads(file: File) {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<any>(`${ environment.api }/import_leads`, formData);
  }

  public getNewLeads() {
    return this.httpClient.get(`${ environment.api }/last_leads`);
  }

  // VISITS
  public getVisits(filters: any, page?: number, sort?: MatSort | null, currentMark?: number | null, limit?: number | null) {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    if (filledFilters.date_from) {
      filledFilters.date_from = moment(filters.date_from).format('Y-MM-DD');
    }

    if (filledFilters.date_to) {
      filledFilters.date_to = moment(filters.date_to).format('Y-MM-DD');
    }

    let params: any = null
    if (page) {
      params = { page, ...filledFilters }
    } else {
      params = { ...filledFilters }
    }

    return this.httpClient.get<any>(`${ environment.api }/visits`, {
      params
    });
  }

  public getVisitTypes() {
    return this.httpClient.get<any>(`${ environment.api }/visit_types`);
  }

  public getVisitStates() {
    return this.httpClient.get<any>(`${ environment.api }/visit_states`);
  }

  public saveVisit(data: any, jobs_f: any, jobs_d: any, visit_actions: any, resolution: any, next_visit: any) {
    data.day = moment(data.day).toString()
    return this.httpClient.post<any>(`${ environment.api }/visits`, {
      ...{ ...data, jobs_f, jobs_d, visit_actions, resolution, next_visit }
    });
  }

  public editVisit(data: any, jobs_f: any, jobs_d: any, visit_actions: any, resolution: any, next_visit: any, id: number) {
    data.day = moment(data.day).toString()
    return this.httpClient.put<any>(`${ environment.api }/visits/${ id }`, {
      ...{ ...data, jobs_f, jobs_d, visit_actions, resolution, next_visit }
    });
  }

  public getVisit(id: number) {
    return this.httpClient.get<any>(`${ environment.api }/visits/${ id }`);
  }

  public validateToken(token: any) {
    return this.httpClient.get<any>(`${ environment.api }/validate_visit_token/${ token.token }`)
  }

  public validateSurveyToken(token: any) {
    return this.httpClient.get<any>(`${ environment.api }/validate_survey_token/${ token.token }`)
  }

  public changeVisitState(data: any, id: number, token: number) {
    return this.httpClient.put<any>(`${ environment.api }/change_visit_state/${ id }`, {
      ...{ ...data, token: token }
    })
  }

  public getDealerJob() {
    return this.httpClient.get(`${ environment.api }/job_dealer_audit`);
  }

  public addDealerJob(name: any) {
    return this.httpClient.post(`${ environment.api }/job_dealer_audit`, {
      name
    });
  }

  public getSurveyJob() {
    return this.httpClient.get(`${ environment.api }/job_survey`);
  }

  public addSurveyJob(name: any) {
    return this.httpClient.post(`${ environment.api }/job_survey`, {
      name
    });
  }

  public getFleetJob() {
    return this.httpClient.get(`${ environment.api }/job_fleet_audit`);
  }

  public addFleetJob(name: any) {
    return this.httpClient.post(`${ environment.api }/job_fleet_audit`, {
      name
    });
  }

  public getAuditsJob(client_id: number) {
    return this.httpClient.get(`${ environment.api }/audit_jobs/${ client_id }`)
  }

  public getSelectedJobs(visit_id: number) {
    return this.httpClient.get(`${ environment.api }/selected_jobs/${ visit_id }`)
  }

  public getCheckedJobs() {
    return this.httpClient.get(`${ environment.api }/checked_jobs`)
  }

  public getCheckedSurvey() {
    return this.httpClient.get(`${ environment.api }/checked_survey`)
  }

  public postDocument(visitId: number, file: File) {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post(`${ environment.api }/add_document/${ visitId }`, formData)
  }

  public showDocument(visitId: number, fileId: number) {
    return this.httpClient.get(`${ environment.api }/visits/${ visitId }/documents/${ fileId }`, {
      responseType: 'arraybuffer',
    });
  }

  public updateDocument(visitId: number, fileId: number, visibility: any) {
    return this.httpClient.put(`${ environment.api }/visits/${ visitId }/documents/${ fileId }`, {
      visibility: visibility.visibility,
    });
  }

  public deleteDocuments(visitId: number, fileId: number) {
    return this.httpClient.delete<any>(`${ environment.api }/visits/${ visitId }/documents/${ fileId }`);
  }

  public evaluateSurvey(visitId: number, data: any, comment: any, token: number) {
    return this.httpClient.post<any>(`${ environment.api }/evaluate_survey/${ visitId }`,
      { survey_eval: data, comment: comment, token: token }
    )
  }

  public sendSurvey(visitId: number) {
    return this.httpClient.get<any>(`${ environment.api }/send_survey/${ visitId }`);
  }

  public getReport(visitId: number) {
    return this.httpClient.get(`${ environment.api }/resolution/${ visitId }`, {
      responseType: 'arraybuffer',
    });
  }

  public getNextVisits() {
    return this.httpClient.get(`${ environment.api }/next_visits`);
  }

  public sendVisitMail(visitId: number) {
    return this.httpClient.get(`${ environment.api }/send_mail/${ visitId }`);
  }

  // KPIS
  public getClientByTypes(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };


      return this.httpClient.get(`${ environment.api }/client_by_types`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/client_by_types`);
    }
  }

  public getVisitByTypes(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/visits_types`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_types`);
    }
  }

  public getScoringAvg(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/visits_scoring`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_scoring`);

    }
  }

  public getVisitFeeling(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };
      return this.httpClient.get<any>(`${ environment.api }/visits_feeling`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_feeling`);
    }
  }

  public getClientsAge(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };


      return this.httpClient.get(`${ environment.api }/client_ages`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/client_ages`);
    }
  }

  public getVisitsSurveys(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/visits_surveys`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_surveys`);
    }
  }

  public getVisitsInfo(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };
      return this.httpClient.get(`${ environment.api }/visits_info`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_info`);
    }
  }

  public getVisitAudit(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/visit_audit`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visit_audit`);
    }
  }

  public getVisitFrec(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/visits_frec`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/visits_frec`);
    }
  }

  public getLostSale(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/lost_sales_kpi`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/lost_sales_kpi`);
    }
  }

  public getDealerSales(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/dealer_sales`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/dealer_sales`);
    }
  }

  public getTopClients(filters?: any, orderBy?: 'eur' | 'unid') {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      if (orderBy != undefined) {
        params['order_by'] = orderBy
      }

      return this.httpClient.get(`${ environment.api }/top_clients`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/top_clients`);
    }
  }

  public getTopWorstClients(filters?: any, orderBy?: 'eur' | 'unid') {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      if (orderBy != undefined) {
        params['order_by'] = orderBy
      }

      return this.httpClient.get(`${ environment.api }/top_worst_clients`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/top_worst_clients`);
    }

  }

  public getTopRZs(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/top_rzs`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/top_rzs`);
    }
  }

  public getVehicleSales(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/info_sales`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/info_sales`);
    }
  }

  public getVehicleSalesPrice(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/info_sales_price`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/info_sales_price`);
    }
  }

  public getCRMInfo(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };

      return this.httpClient.get(`${ environment.api }/crm_info`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/crm_info`);
    }
  }

  public getCompletedTasks(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };
      return this.httpClient.get(`${ environment.api }/completed_tasks`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/completed_tasks`);
    }

  }

  public getUncompletedTasks(filters?: any) {
    if (filters) {
      const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));

      if (filledFilters.init_date) { filledFilters.init_date = moment(filledFilters.init_date).format('Y-MM-DD'); }
      if (filledFilters.final_date) { filledFilters.final_date = moment(filledFilters.final_date).format('Y-MM-DD'); }
      const params: any = { ...filledFilters };
      return this.httpClient.get(`${ environment.api }/uncompleted_tasks`, { params });
    } else {
      return this.httpClient.get(`${ environment.api }/uncompleted_tasks`);
    }
  }

  // TASKS
  public getTask(filters: any, currentMark?: number | null, page?: number, sort?: MatSort | null, limit?: number | null) {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    if (filledFilters.init_date) {
      filledFilters.init_date = moment(filters.init_date).format('Y-MM-DD');
    }

    if (filledFilters.final_date) {
      filledFilters.final_date = moment(filters.final_date).format('Y-MM-DD');
    }

    let params: any = null
    if (page) {
      params = { page, ...filledFilters }
    } else {
      params = { ...filledFilters }
    }

    return this.httpClient.get<any>(`${ environment.api }/tasks`, {
      params
    });
  }

  public showTask(id: number) {
    return this.httpClient.get<any>(`${ environment.api }/tasks/${ id }`);
  }

  public saveTask(data: any) {
    data.date = moment(data.date).format('Y-MM-DD')
    return this.httpClient.post<any>(`${ environment.api }/tasks`, {
      ...{ ...data }
    });
  }

  public editTask(data: any, id: number) {
    data.day = moment(data.day).format('Y-MM-DD')
    return this.httpClient.put<any>(`${ environment.api }/tasks/${ id }`, {
      ...{ ...data }
    });
  }

  public deleteTask(id: number) {
    return this.httpClient.delete<any>(`${ environment.api }/tasks/${ id }`);
  }

  public getTaskStates() {
    return this.httpClient.get<any>(`${ environment.api }/state_task`);
  }

  public changeState(newState: number, taskId: number) {
    return this.httpClient.put<any>(`${ environment.api }/new_state/${ taskId }`, {
      state: newState
    });
  }
}

